
import { computed, defineComponent, ref } from "vue";

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "kt-datatable",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: "No se encontraron registros" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
    currentSortColumn: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    const data = ref(props.tableData);
    const order = ref(props.order);

    const getItems = computed(() => {
      return data.value;
    });

    const pageSize = computed(() => {
      return props.rowsPerPage;
    });

    const currentPageChange = (val) => {
      emit("current-change", parseInt(val));
    };

    const setItemsPerPage = (event) => {
      emit("items-per-page-change", parseInt(event.target.value));
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }
      order.value = order.value === "asc" ? "desc" : "asc";
      emit("sort", { columnName: columnName, order: order.value });
    };

    return {
      currentPageChange,
      getItems,
      sort,
      setItemsPerPage,
      pageSize,
    };
  },
});
