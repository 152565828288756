
import { useStore } from "vuex";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ListAttributes } from "@/core/model/ListAttriibutes";
import { Actions } from "@/store/enums/StoreEnums";
//import ProfilingModalNew from "@/views/profiling/ProfilingModalNew.vue";
import { displayErrors } from "@/core/helpers/errors";
import { User } from "@/core/model/User";

export default defineComponent({
  name: "users-listing",
  components: {
    //ProfilingModalNew,
    Datatable,
  },
  setup() {
    const store = useStore();
    const tableHeader = ref([
      {
        name: "Usuario",
        key: "username",
        sortable: true,
        sortingField: "username",
      },
      {
        name: "Nombre",
        key: "firstname",
        sortable: true,
        sortingField: "firstname",
      },
      {
        name: "Apellidos",
        key: "lastname",
        sortable: true,
        sortingField: "lastname",
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
        sortingField: "email",
      },
      {
        name: "Teléfono",
        key: "phone",
        sortable: true,
        sortingField: "phone",
      },
      {
        name: "Rol",
        key: "role",
        sortable: true,
        sortingField: "role",
      },
      {
        name: "Estado",
        key: "status",
        sortable: true,
        sortingField: "status",
        width: "80px",
      },
      {
        name: "Acciones",
        key: "actions",
        sortable: false,
        width: "80px",
      },
    ]);
    const loading = ref(false);
    const tableData = ref<Array<User>>([]);
    const rowsPerPage = ref(10);
    const currentPage = ref(1);
    const total = ref(0);
    let sortOrder = "asc";
    let sortField = "username";
    let currentSortColumn = ref("usernameasc");
    const search = ref<string>("");
    let timerSearch: number | null = null;

    onMounted(() => {
      setCurrentPageBreadcrumbs("Usuarios", ["Usuarios"]);
      loadData();
    });

    const loadData = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
      } as ListAttributes;
      loading.value = true;
      store
        .dispatch(Actions.USERS_LIST, params)
        .then(() => {
          tableData.value.splice(
            0,
            tableData.value.length,
            ...store.state.UsersModule.list.items
          );
          loading.value = false;
          rowsPerPage.value = store.state.UsersModule.list.limit;
          currentPage.value = store.state.UsersModule.list.page;
          total.value = store.state.UsersModule.list.total;
        })
        .catch(() => {
          displayErrors(store, "UsersModule");
          loading.value = false;
        });
    };

    const searchItems = () => {
        if (timerSearch) {
            clearTimeout(timerSearch);
            timerSearch = null;
        }
        timerSearch = setTimeout(() => {
            loadData();
        }, 800);
    };

    const paginationChangePage = function (page) {
      currentPage.value = page;
      loadData();
    };

    const paginationPerPageChange = function (limit) {
      rowsPerPage.value = limit;
      loadData();
    };

    const sortList = function (data) {
      sortField = data.columnName;
      sortOrder = data.order;
      currentSortColumn.value = sortField + sortOrder;
      loadData();
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      loading,
      rowsPerPage,
      currentPage,
      total,
      currentSortColumn,
      paginationChangePage,
      paginationPerPageChange,
      sortList,
    };
  },
});
